import React from 'react';

function Jellyfish() {
  return (
    <div className="gif-background">
      <img
        className="jellyfish"
        alt="Jellyfish GIF"
        src="./ocean/jellyfish.png"
        style={{ left: '92vw' }}
      />
    </div>
  );
}

export default Jellyfish;
